// extracted by mini-css-extract-plugin
export var accordionBlock = "product-design-module--accordionBlock--61d18";
export var accordionWrapper = "product-design-module--accordionWrapper--974c9";
export var benefits = "product-design-module--benefits--0f76a";
export var benefitsWrapper = "product-design-module--benefitsWrapper--ccb95";
export var billetShadow = "product-design-module--billet-shadow--4b7a5";
export var colorScheme__background__grayPale = "product-design-module--colorScheme__background__gray-pale--1ff64";
export var counterBlock = "product-design-module--counterBlock--b924e";
export var expert = "product-design-module--expert--19db0";
export var flexBlock = "product-design-module--flexBlock--493cd";
export var headline = "product-design-module--headline--ded7d";
export var headline_grid = "product-design-module--headline_grid--96ee0";
export var icomoon = "product-design-module--icomoon--be598";
export var lowercase = "product-design-module--lowercase--06cad";
export var mainPicture = "product-design-module--mainPicture--4646f";
export var mainPicture__mobile = "product-design-module--mainPicture__mobile--15e79";
export var notes = "product-design-module--notes--cce55";
export var optionsList = "product-design-module--optionsList--65037";
export var optionsTextBlock = "product-design-module--optionsTextBlock--89f7c";
export var pic__1 = "product-design-module--pic__1--a9122";
export var pic__2 = "product-design-module--pic__2--3adb0";
export var pic__3 = "product-design-module--pic__3--12794";
export var productDesignBlock = "product-design-module--productDesignBlock--b8abc";
export var strongText = "product-design-module--strongText--0ae52";
export var strongTextBlock = "product-design-module--strongTextBlock--fb60d";
export var timeframe = "product-design-module--timeframe--f4c0d";
export var timeframeContent = "product-design-module--timeframeContent--c08da";
export var timeframeHeading = "product-design-module--timeframeHeading--de84b";
export var transition = "product-design-module--transition--6c08e";
export var transitionBackground = "product-design-module--transition-background--60ada";
export var transitionReverse = "product-design-module--transition-reverse--f838d";
export var twoColumns = "product-design-module--twoColumns--2d1ec";
export var uppercase = "product-design-module--uppercase--e4b4a";
import React from "react"
import { Link } from "gatsby"

import { IMG } from "../const"

export const OPTIONS_LIST = [
  {
    id: 1,
    title: "Startups",
    text:
      "Attract early customers with relevant features that serve user needs.",
  },
  {
    id: 2,
    title: "SMBs",
    text: "Outperform your competitors and achieve defined business goals.",
  },
  {
    id: 3,
    title: "Enterprises",
    text:
      "Develop user-friendly digital experiences for your teams and improve productivity.",
  },
]

export const STAGES = {
  text: "",
  items: [
    {
      id: 1,
      title: "Submit your inquiry",
      text:
        "When you share your project vision, you help us understand your business needs, vision, and initial requirements. This information also outlines your project's possible scope and objectives to develop an appealing design for a new product or redesign the existing one.",
      label: {
        text: "client part",
        dark: true,
      },
    },
    {
      id: 2,
      title: "Discovery process",
      text:
        "This stage involves getting a more detailed review of your project in a one-on-one discovery session. We start with understanding your product goals, target users, and business requirements. We can do market research to test your business idea, and our Business Analyst will help define your product's core functionality. Our Product Designers will help with in-depth user personas and flow research. This research-based approach ensures we brainstorm potential solutions and develop designs that improve product usability.",
      label: {
        text: "client&LaSoft collaboration",
        dark: false,
      },
    },
    {
      id: 3,
      title: "Prototyping",
      text: (
        <p>
          We will create a simple first version of your software product so you
          can test it and get insights from your potential clients. <br />
          We will develop high-fidelity wireframes, an initial version of your
          solution, and, optionally, a prototype, allowing you to test it and
          gather insights from potential users and clients.
        </p>
      ),
    },
    {
      id: 4,
      title: "Get your visual design",
      text:
        "Our product designers aim to create visually engaging interfaces that reflect your business idea and provide brand recognition. We can create interactive prototypes or minimum viable products so you can refine the existing product or test the new one, gathering valuable user feedback. We deliver fully prepared design assets, including detailed Figma files, design systems, and guidelines, to ensure the development team's smooth implementation.",
    },
  ],
}

export const DESIGN_SERVICES = [
  {
    id: 1,
    title: "Can LaSoft handle both design and development?",
    text:
      "LaSoft provides full-cycle software product development, covering the discovery stage, software project cost estimation, product design, development process, and post-launch support. Our dedicated team can support you on every stage of building an MVP or fully-fledged software solution.",
  },
  {
    id: 2,
    title: "How can a system prototype help me validate the market?",
    text: (
      <>
        <p>
          A system prototype is a functional model of your digital product that
          allows you to test your business idea with real users before investing
          in full-cycle software development. Here’s the algorithm of market
          validation flow:
        </p>
        <ul>
          <li>
            <strong>Test user reactions:</strong> Gather feedback from potential
            users to see if your product solves their problem and meets their
            needs.
          </li>
          <li>
            <strong>Evaluate demand:</strong> Understand if there is a
            measurable demand for your product before investing in the
            development process.
          </li>
          <li>
            <strong>Identify improvements:</strong> Discover usability issues,
            feature gaps, or user pain points with early adopters and document
            functionality to add to refine the product.
          </li>
          <li>
            <strong>Attract investors:</strong> If you need fundraising, a
            working prototype showcases your product vision and potential,
            helping you find partners more easily.
          </li>
        </ul>
        <p>
          Overall, a prototype bridges the gap between your concept and a
          market-ready product, ensuring you give your target users what they
          need and reduce costly mistakes.
        </p>
      </>
    ),
  },
  {
    id: 3,
    title: "What is the cost of product design?",
    text: (
      <>
        <p>
          Determining the cost of product design, specifically UI/UX, is often
          project-dependent. It hinges on the complexity of the design and the
          number of screens required. However, we can provide a general
          framework based on our experience. Typically, a single-page design
          requires approximately 10 hours of UI/UX designer time.
        </p>
        <p>
          Some complex pages like dashboards or premium designs may demand 40-60
          hours per page, but you do not have a lot of such pages in a project.
          In most cases, projects are a combination of trivial, average
          complexity, and complex pages.
        </p>
        <p>
          So, we will try to answer based on our experience with different
          project types.
        </p>
        <ul>
          <li>
            <strong>Startups (8-12 pages):</strong> Usually, when you build a
            new project, you do not need more than 8-12 pages, so it will take
            about 100-120 hours, and the cost will be around{" "}
            <strong>$3,000-$3,500</strong>. If you are a startup, we have a
            special offer for you;{" "}
            <Link to="/ui-ux/">please learn more details here</Link>.
          </li>
          <li>
            <strong>
              SMB projects, Portals, Internal Business systems, etc. (9-40
              pages, average 23)
            </strong>
            . These projects generally involve 270-300 hours of design work. The
            estimated cost is around <strong>$8,000-$9,500</strong>.
          </li>
          <li>
            <strong>Enterprise-level</strong> systems are complex by nature, not
            only because of the amount of work but also because of the number of
            stakeholders who have opinions on how the UI/UX should look. In this
            case, you estimate the involvement of UI/UX experts not by the
            number of screens but by the number of months. Cost estimation
            shifts from a per-page basis to a time-based engagement. Design
            costs for enterprise systems often range from{" "}
            <strong>$60,000 to $80,000 or more</strong>.
          </li>
        </ul>
        <p>
          <strong>
            If you need more{" "}
            <Link to="/software-project-estimation/">
              help budgeting your software project
            </Link>
            , please <Link to="/contact/">contact us</Link>. We will provide you
            with an accurate project budget and plan.
          </strong>
        </p>
      </>
    ),
  },
  {
    id: 4,
    title: "Why is discovery important to make a great product design?",
    text: (
      <>
        <p>
          The{" "}
          <a href="https://lasoft.org/blog/on-site-software-project-discovery-for-enterprises-and-smbs/">
            discovery phase
          </a>{" "}
          lays the foundation for the entire design process and successful
          digital product launch. It helps:
        </p>
        <ul>
          <li>
            <strong>Understand user needs:</strong> Identifying pain points,
            behaviors, and preferences ensures the product solves the right
            problems.
          </li>
          <li>
            <strong>Aligning business goals:</strong> Product design should
            reflect the business idea of the product and offer benefits to
            prevent failure in the market with useless functionality.
          </li>
          <li>
            <strong>Define core functionality:</strong> Prioritizing essential
            features allows for building a clear roadmap and preventing
            overcomplication.
          </li>
          <li>
            <strong>Test and Validation:</strong>
            <br />
            <strong>Save time & costs:</strong> Investing in discovery reduces
            the risk of redesign and extra efforts to eliminate possible
            failures.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 5,
    title: "What team do I need for the product design stage?",
    text: (
      <>
        <p>
          A successful digital product design process requires Business Analyst
          and Product Designers to offer efficient digital product design:
        </p>
        <ul>
          <li>
            <strong>Business Analyst:</strong> Our expert does market research
            and ensures your requirements are documented professionally. It
            helps your design to align with business objectives and identifies
            functional requirements.
          </li>
          <li>
            <strong>Product Designers</strong> design user-centered digital
            products and combine appealing UI with functionality. They also do
            user research, develop wireframes and prototypes, and collaborate
            with cross-functional teams to advise on building intuitively
            comprehensive software products. Their role focuses on optimizing
            user experience, supporting business goals, and iterating based on
            client feedback.
          </li>
          <li>
            <strong>System Architect:</strong> Sometimes, for complex,
            innovative projects we also involve a competent System Architect, so
            he can help us with the most appropriate architecture design and
            optimal technologies decisions.
          </li>
        </ul>
      </>
    ),
  },
]

export const TITLES = {
  benefits: {
    title: (
      <>
        <strong>effective</strong> digital product <strong>design</strong> is a
        part of advanced digital product development
      </>
    ),
    strongText: <>we know how to make your digital&nbsp;product a success</>,
    uppercase: <>make design intuitive, increase retention</>,
    withIndent: true,
  },
  stages: {
    title: (
      <>
        <strong>4 Stages</strong> of Digital Product Design Process by LaSoft
      </>
    ),
    strongText: <>we know how to&nbsp;appeal&nbsp;to your target audience</>,
    uppercase: (
      <>
        our designers keep scalability and <br />
        usability in mind
      </>
    ),
    withIndent: true,
  },
  options: {
    title: (
      <>
        LaSoft to design <strong>your digital product?</strong>
      </>
    ),
    strongText: "why choose",
    uppercase: "",
    withIndent: false,
  },
  expert: {
    title: (
      <>
        book a call with the <strong>expert</strong>
      </>
    ),
    strongText: "plan effectively and deliver value",
    uppercase: "get accurate software development estimation",
    withIndent: true,
  },
  accordion: {
    title: (
      <>
        frequently asked <strong>questions</strong>
      </>
    ),
    strongText: "we have answers for you",
    uppercase: "",
    withIndent: false,
  },
  colored: {
    title: (
      <>
        Let us create a product your audience will love and you’ll get a
        revenue–driven product design
      </>
    ),
    strongText: (
      <>
        we are the team that handles both&nbsp;-&nbsp;design & development. we
        know all sides of the story, and we design a product to deliver the
        product
      </>
    ),
    uppercase: "Project estimate",
    link: "#contact-form",
    linkClassName: "design-estimate",
    imageDesktop: `${IMG.PRODUCT_DESIGN}/scheme-white.svg`,
    imageDesktopWidth: 903,
    withIndent: false,
  },
}

export const BENEFITS = [
  {
    id: 1,
    title: "Excellent user experience",
    text:
      "Our experts provide a professional background in creating digital products that ensure users understand, engage, and enjoy the experience. A seamless UX design makes your product more intuitive.",
  },
  {
    id: 2,
    title: "Appealing interface (UI)",
    text:
      "Good product designers create UX that reduces frustration, increases retention, and keeps users engaged. Effective product design responds to business objectives and meets changing user needs, ensuring the product drives revenue in the market.",
  },
  {
    id: 3,
    title: "Optimize your conversion",
    text:
      "Our digital product designers can offer user interface design for your website or e-commerce platform to turn visitors into brand advocates and loyal customers. Design elements, such as call-to-action buttons, optimized checkout processes, and engaging interactive elements, boost conversions.",
  },
  {
    id: 4,
    title: "Save time & budget",
    text:
      "Entrusting design to a professional design team before the software development process helps optimizing time to market and budget.",
  },
  {
    id: 5,
    title: "Achieve business goals",
    text:
      "We create product designs that concentrate on optimizing user flows and enhancing retention. The vital feature of a successful product design is to increase customer satisfaction with an intuitive user experience. With a design that supports your strategy, you achieve your business goals faster and more efficiently.",
  },
  {
    id: 6,
    title: "Stay up-to-date",
    text:
      "We focus on trends and innovations to ensure your product remains competitive. We adapt interfaces and user experiences to meet the latest usability standards and customer behaviors. ",
  },
]

export const OPTIONS = [
  {
    id: 1,
    title: "User-first approach",
    text:
      "Our team focuses on user experience, product features, and usability to provide seamless product flow.",
  },
  {
    id: 2,
    title: "Industry expertise",
    text:
      "10+ years of experience working with startups, SMBs, and enterprises across industries.",
  },
  {
    id: 3,
    title: "Scalable solutions",
    text:
      "Our design systems allow your product to grow without losing usability or brand identity.",
  },
  {
    id: 4,
    title: "Discovery-centered approach",
    text:
      "We use research, analytics, user feedback, and A/B testing to refine designs for maximum efficiency",
  },
  {
    id: 5,
    title: "Support",
    text:
      "Our team has the skills and experience to offer a full-cycle software development process for cross-platform solutions. We can cover the entire digital product design, development, and maintenance process.",
  },
]

export const ANIMATED_COUNTERS = [
  {
    id: 1,
    text: "Cases in portfolio",
    start: 0,
    end: 500,
    delay: 0,
    suffix: "+",
  },
  {
    id: 2,
    text: "Years in the market",
    start: 0,
    end: 10,
    delay: 0,
    suffix: "+",
  },
]

export const TIMEFRAME = [
  {
    id: 1,
    title: "Discovery Process",
    text: (
      <ul>
        <li>Stakeholder/customer/ interview</li>
        <li>General field/Industry/ domain/research</li>
        <li>Detailed competitor analysis</li>
        <li>Business model canvas/VPC</li>
        <li>Persona/proto–persona (optional)</li>
        <li>User flows/user journey map</li>
        <li>Problem statement</li>
      </ul>
    ),
  },
  {
    id: 2,
    title: "Prototyping",
    text: (
      <ul>
        <li>Information architecture/ mind map</li>
        <li>Wireframe</li>
        <li>Prototyping</li>
        <li>High-fidelity wireframes (optional)</li>
        <li>Idea testing</li>
      </ul>
    ),
  },
  {
    id: 3,
    title: "Get your visual design",
    text: (
      <ul>
        <li>Build a UI(user interface) for a product</li>
        <li>Create a style guideline/ design system/design tokens</li>
        <li>We create responsive designs for all devices</li>
        <li>We transfer all artefacts and designs to the client.</li>
      </ul>
    ),
  },
]

import React, { useState, useEffect, useRef } from "react"
import cx from "classnames"
import { Link } from "gatsby"
import CountUp from "react-countup"

import {
  OPTIONS_LIST,
  DESIGN_SERVICES,
  TITLES,
  STAGES,
  BENEFITS,
  OPTIONS,
  ANIMATED_COUNTERS,
  TIMEFRAME,
} from "../data/product-design"
import { IMG } from "../const"
import Layout from "../components/layout"
import "../assets/styles/commons/_common-kit.scss"
import Headline from "../components/headline"
import ServicesList from "../components/services-list"
import Accordion from "../components/accordion"
import TitleBlock from "../components/title-block"
import ContactForm from "../components/contact-form"
import ColoredSection from "../components/colored-section"
import * as styles from "../assets/styles/product-design.module.scss"

const ProductDesign = () => {
  const [startCount, setStartCount] = useState(false)
  const countAnimationDuration = 5
  const counterBlock = useRef(null)

  useEffect(() => {
    const handleScroll = e => {
      const counterBlockOffset = counterBlock.current.getBoundingClientRect()
      const counterBlockPosition = counterBlockOffset.top - 200
      if (!startCount && counterBlockPosition < 0) {
        setStartCount(true)
      }
    }

    document.addEventListener("scroll", handleScroll, { passive: true })
    return function cleanup() {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [startCount])

  const onCountUpEnd = () => {
    let spans = document.querySelectorAll(".plusSpan")

    spans.forEach(span => {
      span.innerText = "+"
    })
  }

  return (
    <Layout
      pageTitle="Product Design Service"
      defaultImage={`${IMG.THUMBNAILS}/thumbnails-digital-product-design.jpg`}
    >
      {({
        width,
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <main className={cx(styles.productDesignBlock, "common-kit")}>
            <article>
              <Headline>
                <div className={cx(styles.headline, "headline")}>
                  <div className={cx(styles.headline_grid, "headline_grid")}>
                    <p className={"strongText"}>
                      we bring business ideas to life
                    </p>
                    <h1 className={"withIndent"}>
                      <strong>Digital</strong> <br />
                      product&nbsp;design <br />
                      service
                    </h1>
                    <div className={styles.mainPicture__mobile}>
                      <picture>
                        <source
                          media="(max-width: 432px)"
                          srcSet={`${IMG.PRODUCT_DESIGN}/product-design-mob-1@3x.avif`}
                          type="image/avif"
                        />
                        <source
                          media="(max-width: 432px)"
                          srcSet={`${IMG.PRODUCT_DESIGN}/product-design-mob-1@3x.webp`}
                          type="image/webp"
                        />
                        <source
                          media="(max-width: 432px)"
                          srcSet={`${IMG.PRODUCT_DESIGN}/product-design-mob-1@3x.png`}
                        />
                        <img
                          src={`${IMG.PRODUCT_DESIGN}/product-design-mob-1@3x.png`}
                          alt=""
                          width="198"
                          height="346"
                          loading="lazy"
                          className={styles.pic__1}
                        />
                      </picture>
                      <picture>
                        <source
                          media="(max-width: 432px)"
                          srcSet={`${IMG.PRODUCT_DESIGN}/product-design-mob-2@3x.avif`}
                          type="image/avif"
                        />
                        <source
                          media="(max-width: 432px)"
                          srcSet={`${IMG.PRODUCT_DESIGN}/product-design-mob-2@3x.webp`}
                          type="image/webp"
                        />
                        <source
                          media="(max-width: 432px)"
                          srcSet={`${IMG.PRODUCT_DESIGN}/product-design-mob-2@3x.png`}
                        />
                        <img
                          src={`${IMG.PRODUCT_DESIGN}/product-design-mob-2@3x.png`}
                          alt=""
                          width="196"
                          height="222"
                          loading="lazy"
                          className={styles.pic__2}
                        />
                      </picture>
                      <picture>
                        <source
                          media="(max-width: 432px)"
                          srcSet={`${IMG.PRODUCT_DESIGN}/product-design-mob-3@3x.avif`}
                          type="image/avif"
                        />
                        <source
                          media="(max-width: 432px)"
                          srcSet={`${IMG.PRODUCT_DESIGN}/product-design-mob-3@3x.webp`}
                          type="image/webp"
                        />
                        <source
                          media="(max-width: 432px)"
                          srcSet={`${IMG.PRODUCT_DESIGN}/product-design-mob-3@3x.png`}
                        />
                        <img
                          src={`${IMG.PRODUCT_DESIGN}/product-design-mob-3@3x.png`}
                          alt=""
                          width="248"
                          height="261"
                          loading="lazy"
                          className={styles.pic__3}
                        />
                      </picture>
                    </div>
                    <div className={"rightColumn"}>
                      <p>Let's create an excellent user experience together</p>
                      <div>
                        <Link
                          to="#contact-form"
                          className="goldFatLink design-talk"
                        >
                          Let’s talk
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Headline>

              <section className={cx(styles.mainPicture, "section")}>
                <picture>
                  <source
                    media="(min-width: 432px)"
                    srcSet={`${IMG.PRODUCT_DESIGN}/product-design_desktop.avif,  ${IMG.PRODUCT_DESIGN}/product-design_desktop@2x.avif 2x, ${IMG.PRODUCT_DESIGN}/product-design_desktop@3x.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 432px)"
                    srcSet={`${IMG.PRODUCT_DESIGN}/product-design_desktop.webp,  ${IMG.PRODUCT_DESIGN}/product-design_desktop@2x.webp 2x, ${IMG.PRODUCT_DESIGN}/product-design_desktop@3x.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(min-width: 432px)"
                    srcSet={`${IMG.PRODUCT_DESIGN}/product-design_desktop.png,  ${IMG.PRODUCT_DESIGN}/product-design_desktop@2x.png 2x, ${IMG.PRODUCT_DESIGN}/product-design_desktop@3x.png 3x`}
                  />
                  <img
                    src={`${IMG.PRODUCT_DESIGN}/product-design_desktop.png`}
                    alt="Screen of a dashboard"
                    width="1122"
                    height="582"
                    loading="lazy"
                  />
                </picture>
              </section>

              <section className={"section"}>
                <div className={styles.flexBlock}>
                  <div className={styles.strongTextBlock}>
                    <p className={styles.strongText}>
                      our digital product design services are for:
                    </p>
                  </div>
                  <div>
                    <ul className={styles.optionsList}>
                      {OPTIONS_LIST.map(option => (
                        <li key={option.id}>
                          <h3>{option.title}</h3>
                          <p>{option.text}</p>
                        </li>
                      ))}
                    </ul>

                    <h2>
                      Get outcome focused
                      <strong> digital product design </strong>
                      services from LaSoft
                    </h2>
                    <div className={styles.twoColumns}>
                      <p>
                        <strong>
                          We offer our clients professional digital product
                          design services that visualize your product concept
                          and ensure intuitive usability and positive feedback
                          from your target audience.
                        </strong>
                      </p>
                      <p>
                        With 10+ years of experience and a portfolio of 500+
                        business cases discovered, our software product
                        development team helps businesses create appealing
                        designs to drive real impact on actual users and strike
                        a balance between user experiences and the functionality
                        of the digital product.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section className={"section"}>
                <TitleBlock options={TITLES.benefits} />
                <div className={styles.benefitsWrapper}>
                  <ul className={styles.benefits}>
                    {BENEFITS.map(item => (
                      <li key={item.id}>
                        <h3>{item.title}</h3>
                        <p>{item.text}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </section>

              <section className={"section"}>
                <TitleBlock options={TITLES.stages} />
                <ServicesList services={STAGES.items} />
              </section>

              <ColoredSection options={TITLES.colored} />

              <section className={cx(styles.timeframe, "section")}>
                <div ref={counterBlock} className={styles.timeframeHeading}>
                  <div>
                    <h2>
                      <strong>How long</strong> does it take to design a digital
                      product?
                    </h2>
                    <p>
                      The timeframe for digital product design varies based on
                      project complexity and scope. We can offer approximate
                      timeframes so you could plan your workflow.
                    </p>
                  </div>
                  <div className={styles.counterBlock}>
                    <ul>
                      {ANIMATED_COUNTERS.map(item => (
                        <li key={item.id}>
                          {startCount && (
                            <CountUp
                              start={item.start}
                              end={item.end}
                              delay={item.delay}
                              duration={countAnimationDuration}
                              useEasing={false}
                              onEnd={onCountUpEnd}
                            >
                              {({ countUpRef }) => (
                                <strong>
                                  <span ref={countUpRef} />
                                  <span className="plusSpan" />
                                </strong>
                              )}
                            </CountUp>
                          )}
                          <div>{item.text}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className={styles.timeframeContent}>
                  <ul>
                    {TIMEFRAME.map(item => (
                      <li key={item.id}>
                        <div>
                          <h3>{item.title}</h3>
                          {item.text}
                        </div>
                      </li>
                    ))}
                  </ul>
                  <span className={styles.notes}>
                    *100% accuracy only possible to achieve when project is
                    already finished.
                  </span>
                </div>
              </section>

              <section className={"section"}>
                <TitleBlock options={TITLES.options} />
                <div className={styles.optionsTextBlock}>
                  <p>
                    <strong>
                      Our expertise spans different industries, and we ensure
                      that each project benefits from a strategic, user-centered
                      design approach.
                    </strong>
                  </p>
                  <p>
                    We focus on designing solutions that follow specific
                    industry requirements and users' needs. Our UI/UX designers
                    and business analysts develop design solutions to make your
                    product commercially successful in the competitive market.
                  </p>
                </div>
                <ServicesList services={OPTIONS} />
              </section>

              <section className={cx(styles.expert, "section")}>
                <TitleBlock options={TITLES.expert} />
                <div className={styles.twoColumns}>
                  <div>
                    <picture>
                      <source
                        srcSet={`${IMG.PRODUCT_DESIGN}/Vadym-Shaposhnyk.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.PRODUCT_DESIGN}/Vadym-Shaposhnyk.webp`}
                        type="image/webp"
                      />
                      <source
                        srcSet={`${IMG.PRODUCT_DESIGN}/Vadym-Shaposhnyk.jpg`}
                      />
                      <img
                        src={`${IMG.PRODUCT_DESIGN}/Vadym-Shaposhnyk.jpg`}
                        alt="Vadym Shaposhnyk, Chief Commercial Officer"
                        width="288"
                        height="488"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div>
                    <h3>Vadym Shaposhnyk</h3>
                    <span>Art Director/Product Designer in LaSoft</span>
                    <p>
                      <span />
                      Design isn’t just about looks—it’s about impact. At
                      LaSoft, we create digital products that blend aesthetics
                      with functionality, streamlining workflows, optimizing
                      budgets, and speeding up time to market. The key to
                      success?
                    </p>
                    <p>
                      Prioritizing user needs, not unnecessary features.
                      Simplicity drives the best products—and the best results.
                    </p>
                    <Link to="#contact-form" className="goldFatLink">
                      Book a call with our expert
                    </Link>
                  </div>
                </div>
              </section>

              <section className={cx("section", styles.accordionBlock)}>
                <TitleBlock options={TITLES.accordion} />
                <div className={styles.accordionWrapper}>
                  <Accordion options={DESIGN_SERVICES} />
                </div>
              </section>
            </article>
            <section className="footerForm section" id="contact-form">
              <ContactForm
                formStatID="CommonFooter"
                handleOpenConfirmation={handleOpenConfirmation}
                isOpenConfirmation={isOpenConfirmation}
                setConfirmationOption={setConfirmationOption}
              />
            </section>
          </main>
        )
      }}
    </Layout>
  )
}

export default ProductDesign
